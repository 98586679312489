import { DEFAULT_LOCALE, PUBLIC_ASSET_URL, PUBLIC_URL } from "config/system";

const getStrapiAttributes = (entry) => entry?.attributes || null;

const getImageData = (image) =>
  image
    ? {
        url: `${PUBLIC_ASSET_URL}${
          image?.url ||
          image.formats?.thumbnail?.url ||
          image.formats?.small?.url
        }`,
        alt: image.alternativeText,
        title: image.caption,
      }
    : {
        url: "/images/placeholder.png",
        alt: "placeholder",
        title: "placeholder",
      };

const convertStrapiData = (data) =>
  data.map((item) => ({ ...item.attributes, id: item.id }));

const getLocalisedLink = (locale, domain, slug) => {
  const loc = locale === DEFAULT_LOCALE ? "/" : `/${locale}/`;
  return `${PUBLIC_URL}${loc}${domain ? `${domain}/` : ""}${slug}`;
};

export {
  getStrapiAttributes,
  convertStrapiData,
  getImageData,
  getLocalisedLink,
};
