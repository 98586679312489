const SORT_OPTION = {
  price_asc: "lowest_price_net:asc",
  price_desc: "lowest_price_net:desc",
};

const SORT_OPTIONS = [SORT_OPTION.price_asc, SORT_OPTION.price_desc];
const DEFAULT_ITEMS_PER_PAGE = 100;

const CAPTCHA_WIDTH = 500;
const CAPTCHA_HEIGHT = 200;

const OPERATOR_NAME = "LaboRent";

export {
  SORT_OPTIONS,
  CAPTCHA_WIDTH,
  CAPTCHA_HEIGHT,
  DEFAULT_ITEMS_PER_PAGE,
  SORT_OPTION,
  OPERATOR_NAME,
};
