import { useTranslations } from "next-intl";
import Image from "next/future/image";

import theme from "config/theme";
import { Body, H1, Small } from "components/atoms/Typography";
import { Grid, Link as ExternalLink } from "components/MUI";
import {
  TeaserBoxWithImage,
  TeaserBoxImage,
  TeaserImageGrid,
} from "./Teaser.styles";

function AboutUsTeaser() {
  const localePrefix = "ueber_uns_teaser";
  const t = useTranslations();

  return (
    <Grid container spacing={theme.spacing(2)}>
      <Grid item md={6} xs={12} order={{ md: 0, xs: 1 }}>
        <H1 sx={{ mt: { md: theme.spacing(10), xs: theme.spacing(4) } }}>
          {t("ueber_uns_teaser.h1")}
          <Body
            variant="h1"
            component="span"
            color={theme.palette.primary.main}
            sx={{ fontWeight: 300 }}
          >
            {t("ueber_uns_teaser.h1_subtext")}
          </Body>
        </H1>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ mt: theme.spacing(6) }}
        >
          <Grid
            container
            item
            order={{ md: 1, xs: 0 }}
            spacing={theme.spacing(2)}
          >
            <Grid
              item
              md={6}
              xs={12}
              display="flex"
              justifyContent={{ md: "start", xs: "center" }}
              alignItems="center"
            >
              <Small color={theme.palette.custom.darkGrey}>
                {t(`${localePrefix}.our_partners`)}
              </Small>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              gap={{ xs: theme.spacing(3), md: theme.spacing(2) }}
            >
              <ExternalLink
                href="https://www.weiss-technik.com"
                target="_blank"
                rel="noopener"
              >
                <Image
                  height={44}
                  width={119}
                  priority
                  loading="eager"
                  title={t(`${localePrefix}.weiss_technik_logo_alternative`)}
                  alt={t(`${localePrefix}.weiss_technik_logo_alternative`)}
                  src="/images/weiss_technik_logo.svg"
                />
              </ExternalLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TeaserImageGrid item md={6} xs={12} order={{ md: 1, xs: 0 }}>
        <TeaserBoxWithImage>
          <TeaserBoxImage
            src="/images/placeholder.png"
            priority
            loading="eager"
            fill
            alt={t(`${localePrefix}.image_alternative`)}
            title={t(`${localePrefix}.image_title`)}
            sizes="(max-width: 768px) 400px,
              (max-width: 1200px) 600px,
              600px"
          />
        </TeaserBoxWithImage>
      </TeaserImageGrid>
    </Grid>
  );
}

export default AboutUsTeaser;
