import Image from "next/future/image";
import { Box, Button, Grid, TextField, styled } from "components/MUI";
import markdownTheme from "config/markdownTheme";
import pageTheme from "config/theme";

const SearchField = styled(TextField)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    backgroundColor: "#fff",
    boxShadow: theme.shadows[3],
    input: {
      fontSize: "1rem",
      border: 0,
      "&::placeholder": {
        color: "#566983",
        fontStyle: "italic",
        opacity: 1,
        fontSize: "1rem",
      },
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
}));

const HeaderBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "paddingBottom",
})(({ theme, paddingBottom = 2 }) => ({
  padding: `${theme.spacing(4)} 0 ${theme.spacing(paddingBottom)} 0`,
  background:
    "linear-gradient(0deg, rgba(234, 244, 255, 0.3), rgba(234, 244, 255, 0.3)), url(/images/header_background.png)",
}));

const NavigationBox = styled("nav")(({ theme }) => ({
  marginTop: theme.spacing(6),
  "> .MuiList-root": {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    "> li": {
      width: "fit-content",
    },
  },
}));

const StyledMenuButton = styled(Button)(() => ({
  fontSize: "0.875rem",
  width: "max-content",
  padding: 0,
  ":hover,:focus,:active": {
    backgroundColor: "inherit",
    textDecoration: "underline",
  },
}));

const ErrorHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "3.5rem",
  padding: `${theme.spacing(10)} 0`,

  "@media (max-width: 800px)": {
    gap: "2rem",
    "> svg": {
      display: "none",
    },
  },
}));

const ErrorParagraphWrapper = styled("div")(({ theme }) => ({
  padding: `${theme.spacing(10)} 0 ${theme.spacing(5)} 0`,
  justifyContent: "center",
  maxWidth: "670px",
  textAlign: "center",
  margin: "0 auto",
}));

const HeaderBoxWithImage = styled(Box)(({ theme }) => ({
  "&:before": {
    content: "''",
    zIndex: "0",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: "linear-gradient(144deg, #F7F9FA 0%, #EFF1F6 100%)",
    transform: "translate3d(0%, 20%, 0)",
    width: "90%",
    [theme.breakpoints.down("lg")]: {
      transform: "translate3d(-10%, 20%, 0) scale(1, 0.8)",
    },
    [theme.breakpoints.down("md")]: {
      transform: "translate3d(0%, 20%, 0)",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "translate3d(0%, 20%, 0)  scale(1, 0.8)",
    },
  },
  position: "relative",
  minHeight: "350px",
  [theme.breakpoints.down("md")]: {
    minHeight: "400px",
    width: "80vw",
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: "200px",
    width: "80vw",
  },
}));

const HeaderBoxImage = styled(Image)(({ theme }) => ({
  objectFit: "contain",
  objectPosition: "right",
  [theme.breakpoints.down("md")]: {
    width: "80vw",
  },
}));

const HeaderBoxImageGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const blogHeaderStyles = {
  ...markdownTheme,
  span: {
    component: "p",
    props: {
      style: {
        marginTop: pageTheme.spacing(2),
        marginBottom: pageTheme.spacing(5),
        color: pageTheme.palette.primary.main,
        fontSize: "1.125rem",
        lineHeight: "1.688rem",
        fontWeight: 600,
      },
    },
  },
};

export {
  SearchField,
  HeaderBox,
  NavigationBox,
  StyledMenuButton,
  ErrorHeader,
  ErrorParagraphWrapper,
  HeaderBoxImage,
  HeaderBoxWithImage,
  HeaderBoxImageGrid,
  blogHeaderStyles,
};
