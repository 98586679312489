/* eslint-disable react/no-unstable-nested-components */
import { useTranslations } from "next-intl";
import Image from "next/future/image";
import T from "prop-types";

import theme from "config/theme";
import {
  Body,
  H1,
  Small,
  SmallBold,
  Caption,
  H4,
} from "components/atoms/Typography";
import { Grid, Link as ExternalLink, Box, useMediaQuery } from "components/MUI";
import Link from "components/Link";
import CustomIcon from "components/atoms/CustomIcon";

import {
  TeaserBoxBackground,
  TeaserBoxLine,
  TeaserCard as TeaserCardElement,
  TeaserCardImageWrapper,
  TeaserCardImage,
} from "./Teaser.styles";

function TeaserCard({ label, description, image, title, mobile, icon }) {
  if (mobile) {
    return (
      <TeaserCardElement mobile={mobile}>
        <Box>
          <TeaserCardImage
            loading="eager"
            width={108}
            height={85}
            alt=""
            sizes="240px"
            src={`/images/${image}`}
          />
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          flexDirection="column"
          ml={theme.spacing(2)}
        >
          <Body
            as="p"
            sx={{
              color: theme.palette.custom.brightBlue,
            }}
          >
            {label}
            <CustomIcon
              name={icon}
              fill={theme.palette.custom.brightBlue}
              sx={{
                width: "22px",
                height: "22px",
                right: theme.spacing(2),
                top: theme.spacing(2),
                position: "absolute",
              }}
            />
          </Body>
          <H4
            as="p"
            sx={{
              mt: theme.spacing(1),
              lineHeight: 1.4,
            }}
          >
            {title}
          </H4>
        </Box>
      </TeaserCardElement>
    );
  }

  return (
    <TeaserCardElement mobile={mobile}>
      <TeaserCardImageWrapper>
        <TeaserCardImage
          loading="eager"
          fill
          alt=""
          sizes="240px"
          src={`/images/${image}`}
        />
      </TeaserCardImageWrapper>
      <CustomIcon
        name={icon}
        className="hide"
        fill={theme.palette.custom.brightBlue}
        sx={{
          width: "32px",
          height: "32px",
          right: theme.spacing(2),
          top: theme.spacing(2),
          position: "absolute",
          display: "none",
        }}
      />
      <Small sx={{ color: theme.palette.custom.brightBlue }}>{label}</Small>
      <SmallBold
        as="p"
        sx={{
          color: theme.palette.custom.black,
          mt: theme.spacing(1),
          lineHeight: 1.4,
        }}
      >
        {title}
      </SmallBold>
      <Caption as="p" sx={{ mt: theme.spacing(2) }}>
        {description}
      </Caption>
    </TeaserCardElement>
  );
}

TeaserCard.propTypes = {
  title: T.string.isRequired,
  description: T.string,
  icon: T.string,
  image: T.string.isRequired,
  label: T.string.isRequired,
  mobile: T.bool,
};

TeaserCard.defaultProps = {
  description: null,
  mobile: false,
  icon: null,
};

function HomeTeaser() {
  const localePrefix = "home_teaser";
  const t = useTranslations();
  const isTooSmall = useMediaQuery("(max-width:1207px)");

  return (
    <Grid container spacing={theme.spacing(2)}>
      <Grid item md={6}>
        <SmallBold>{t("home_teaser.company_exclusive")}</SmallBold>
        <H1
          sx={{
            mt: { xs: theme.spacing(1) },
            maxWidth: isTooSmall ? "500px" : "100%",
          }}
        >
          {t("home_teaser.h1")}
        </H1>
      </Grid>
      <Grid item md={6} />
      <Grid item md={5} />
      <Grid item md={7} position="relative">
        <TeaserBoxBackground />
      </Grid>
      <Grid item md={4} xs={12}>
        <Body as="p">
          {t.rich("home_teaser.h1_subtext", {
            strong: (text) => <b>{text}</b>,
          })}
        </Body>
        <Grid
          container
          item
          lg={8}
          md={10}
          xs={12}
          spacing={3}
          sx={{ mt: theme.spacing(1) }}
        >
          <Grid item xs={12} order={{ md: 0, xs: 2 }}>
            <Link
              type="button"
              variant="contained"
              size="large"
              disableElevation
              fullWidth
              href={t("links.product_overview.link")}
            >
              {t(`${localePrefix}.btn_discover_products`)}
            </Link>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sx={{ mt: theme.spacing(3) }}
            order={{ md: 0, xs: 1 }}
          >
            <Small
              as="p"
              textAlign={{ md: "left", xs: "center" }}
              color={theme.palette.custom.darkGrey}
            >
              {t(`${localePrefix}.our_partners`)}
            </Small>
          </Grid>
          <Grid
            item
            md={9}
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            gap={{ xs: theme.spacing(3), md: theme.spacing(2) }}
            order={{ md: 0, xs: 1 }}
          >
            <ExternalLink
              href="https://www.weiss-technik.com"
              target="_blank"
              rel="noopener"
            >
              <Image
                height={30}
                width={80}
                priority
                loading="eager"
                alt={t(`${localePrefix}.weiss_technik_logo_alternative`)}
                src="/images/weiss_technik_logo.svg"
              />
            </ExternalLink>
          </Grid>
          <Grid
            container
            item
            xs={12}
            display={{ md: "none", xs: "block" }}
            spacing={theme.spacing(1)}
            order={{ md: 0, xs: 0 }}
          >
            {[1, 2, 3].map((key) => (
              <Grid key={key} item xs={12}>
                <TeaserCard
                  label={t(`${localePrefix}.cards.mobile.${key}.label`)}
                  title={t(`${localePrefix}.cards.mobile.${key}.title`)}
                  image={t(`${localePrefix}.cards.mobile.${key}.image`)}
                  icon={t(`${localePrefix}.cards.mobile.${key}.icon`)}
                  mobile
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        md={8}
        display={{ md: "block", xs: "none" }}
        position="relative"
        order={{ md: 0, xs: 0 }}
      >
        <Body
          sx={{
            color: theme.palette.custom.brightBlue,
            mt: "-70px",
            position: "absolute",
            pl: theme.spacing(1),
          }}
        >
          {t(`${localePrefix}.how_it_works`)}
        </Body>
        <Grid
          item
          container
          spacing={theme.spacing(2)}
          sx={{
            mt: "-50px",
          }}
        >
          <TeaserBoxLine />
          {[1, 2, 3].map((key) => (
            <Grid key={key} item xs={4}>
              <TeaserCard
                label={t(`${localePrefix}.cards.desktop.${key}.label`)}
                title={t(`${localePrefix}.cards.desktop.${key}.title`)}
                description={t(
                  `${localePrefix}.cards.desktop.${key}.description`
                )}
                image={t(`${localePrefix}.cards.desktop.${key}.image`)}
                icon={t(`${localePrefix}.cards.desktop.${key}.icon`)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default HomeTeaser;
