import { useTranslations } from "next-intl";
import { useState } from "react";
import T from "prop-types";
import { useRouter } from "next/router";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Container,
  Toolbar,
  List,
  ListItem,
  Box,
  Menu,
  Divider,
  IconButton,
  ListItemButton,
  ListItemText,
  Collapse,
} from "components/MUI";
import Link from "components/Link";
import { LogoTypography } from "components/atoms/Typography";
import theme from "config/theme";
import CustomIcon from "components/atoms/CustomIcon";

import NAVIGATION_LINKS from "./config";
import {
  StyledListItemButton,
  StyledMenuButton,
  StyledAppBar,
} from "./Navbar.styles";
import useShouldShowBackground from "./useShouldShowBackground";
import NewsletterDialog from "./NewsletterDialog";

function Navbar({ navigationData, variant }) {
  const router = useRouter();
  const t = useTranslations();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMobNav, setAnchorElMobNav] = useState(null);

  const [mobileProductSub, setMobileProductSub] = useState(null);
  const mobileProductSubOpen = Boolean(mobileProductSub);
  const open = Boolean(anchorEl);

  const shouldShowBackground = useShouldShowBackground();

  const handleOpenNavMenu = (event) => {
    setAnchorElMobNav(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElMobNav(null);
  };

  const handleMobileSubClick = (event) => {
    setMobileProductSub(mobileProductSub ? null : event.currentTarget);
  };

  return (
    <StyledAppBar
      customStyle={shouldShowBackground ? variant : null}
      component="nav"
      position="sticky"
      elevation={0}
      aria-label="main"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <div style={{ flexGrow: 1 }}>
            <Link href="/" underline="none" alignItems="center" display="flex">
              <LogoTypography sx={{ color: theme.palette.custom.darkGrey }}>
                {t("platform_name")}
              </LogoTypography>
            </Link>
          </div>
          {/* MOBILE */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="mobile menu"
              aria-controls="mobile-menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              {anchorElMobNav ? (
                <CustomIcon
                  fill={theme.palette.custom.black}
                  name="menuOpened"
                />
              ) : (
                <CustomIcon
                  fill={theme.palette.custom.black}
                  name="menuClosed"
                />
              )}
            </IconButton>
            <Menu
              id="mobile-menu-appbar"
              anchorEl={anchorElMobNav}
              marginThreshold={0}
              PaperProps={{
                style: {
                  width: "100%",
                  maxWidth: "100%",
                  left: 0,
                  right: 0,
                },
                elevation: 1,
              }}
              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
              transformOrigin={{ horizontal: "center", vertical: "top" }}
              open={Boolean(anchorElMobNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {NAVIGATION_LINKS.map((link) => {
                if (link.submenu?.length > 0) {
                  return [
                    <StyledListItemButton onClick={handleMobileSubClick}>
                      <ListItemText primary={t(`links.${link.title}`)} />
                      {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </StyledListItemButton>,
                    <Collapse
                      in={mobileProductSubOpen}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {link.submenu.map((staticLink) => (
                          <ListItemButton
                            key={`sub-nav-${staticLink.title}.link`}
                            onClick={handleCloseNavMenu}
                            disableRipple
                            sx={{ pl: "3rem" }}
                          >
                            <Link
                              href={t(`links.${staticLink.title}.link`)}
                              underline="none"
                              alignItems="center"
                              display="flex"
                              isActive={
                                router.asPath ===
                                t(`links.${staticLink.title}.link`)
                              }
                            >
                              {t(`links.${staticLink.title}.title`)}
                            </Link>
                          </ListItemButton>
                        ))}

                        {navigationData?.map((subLink) => [
                          <ListItemButton
                            key={`sub-nav-${subLink.slug}`}
                            onClick={handleCloseNavMenu}
                            disableRipple
                            sx={{ pl: "3rem" }}
                          >
                            <Link
                              href={`/${subLink.slug}`}
                              underline="none"
                              alignItems="center"
                              display="flex"
                              isActive={router.asPath === `/${subLink.slug}`}
                            >
                              {subLink.name}
                            </Link>
                          </ListItemButton>,
                        ])}
                      </List>
                    </Collapse>,
                  ];
                }

                return (
                  <Link
                    type="menu_item"
                    href={t(`links.${link.title}.link`)}
                    key={link.title}
                    underline="hover"
                    size="small"
                    onClick={handleCloseNavMenu}
                    {...(link.type !== "button" && {
                      isActive:
                        router.asPath === t(`links.${link.title}.link`) ||
                        router.route === t(`links.${link.title}.link`) ||
                        router.asPath.includes(
                          `${t(`links.${link.title}.link`)}/`
                        ),
                    })}
                  >
                    {t(`links.${link.title}.title`)}
                  </Link>
                );
              })}
              <NewsletterDialog mobile />
            </Menu>
          </Box>

          {/* DESKTOP */}
          <Box component={List} sx={{ display: { xs: "none", md: "flex" } }}>
            {NAVIGATION_LINKS.map((link) => {
              if (link.submenu?.length > 0) {
                return (
                  <ListItem sx={{ width: "max-content" }} key={link.title}>
                    <StyledMenuButton
                      id="products-button"
                      size="medium"
                      aria-controls={open ? "products-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="text"
                      disableElevation
                      onClick={handleClick}
                      endIcon={
                        open ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      }
                    >
                      {t(`links.${link.title}`)}
                    </StyledMenuButton>
                    <Menu
                      id="products-menu"
                      MenuListProps={{
                        "aria-labelledby": "products-button",
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorEl={anchorEl}
                      elevation={1}
                      open={open}
                      onClose={handleClose}
                      sx={{ display: { xs: "none", md: "flex" } }}
                    >
                      {[
                        ...link.submenu.map((staticLink) => [
                          <Link
                            type="menu_item"
                            href={t(`links.${staticLink.title}.link`)}
                            key={`${staticLink.title}-submenu`}
                            onClick={handleClose}
                            underline="none"
                            isActive={
                              router.asPath ===
                              t(`links.${staticLink.title}.link`)
                            }
                          >
                            {t(`links.${staticLink.title}.title`)}
                          </Link>,
                          staticLink.withDivider && (
                            <Divider key={`${staticLink.title}-divider`} />
                          ),
                        ]),
                        ...navigationData.map((subLink) => [
                          <Link
                            type="menu_item"
                            href={`/${subLink.slug}`}
                            key={subLink.slug}
                            onClick={handleClose}
                            disableRipple
                            underline="none"
                            isActive={router.asPath === `/${subLink.slug}`}
                          >
                            {subLink.name}
                          </Link>,
                        ]),
                      ]}
                    </Menu>
                  </ListItem>
                );
              }

              return (
                <ListItem sx={{ width: "max-content" }} key={link.title}>
                  <Link
                    href={t(`links.${link.title}.link`)}
                    type={link.type}
                    underline="hover"
                    size="small"
                    {...(!link.action && {
                      isActive:
                        router.asPath === t(`links.${link.title}.link`) ||
                        router.route === t(`links.${link.title}.link`) ||
                        router.asPath.includes(
                          `${t(`links.${link.title}.link`)}/`
                        ),
                    })}
                  >
                    {t(`links.${link.title}.title`)}
                  </Link>
                </ListItem>
              );
            })}
            <NewsletterDialog />
          </Box>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
}

Navbar.propTypes = {
  navigationData: T.arrayOf(T.shape({})).isRequired,
  variant: T.string,
};
Navbar.defaultProps = {
  variant: null,
};

export default Navbar;
