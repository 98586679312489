import { Container, styled } from "components/MUI";

const FooterContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrast,
}));

const FooterInnerContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  justifyItems: "center",
  flexWrap: "wrap",
  padding: "1rem",
  [theme.breakpoints.down("xl")]: {
    padding: "2rem",
    flexDirection: "column",
  },
  "& nav": {
    display: "flex",
    flexBasis: "55%",
    justifyContent: "flex-end",
    gap: "1rem 3rem",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    "& ul": {
      gap: "0rem 1rem",
      listStyleType: "none",
      paddingInlineStart: 0,
      flexDirection: "column",
      display: "flex",
      "& li": {
        padding: "0.2rem 0rem",
        [theme.breakpoints.down("md")]: {
          display: "inline",
          textAlign: "center",
          padding: "0.3rem",
        },
      },
    },
  },
}));

export { FooterContainer, FooterInnerContainer };
