import T from "prop-types";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";

import Link from "components/Link";
import { LogoTypography } from "components/atoms/Typography";
import theme from "config/theme";

import { composeNavigation } from "./config";
import { FooterContainer, FooterInnerContainer } from "./Footer.styles";

function Footer({ navigationData, manufacturerData }) {
  const router = useRouter();
  const t = useTranslations();

  const navigationItems = composeNavigation(navigationData, manufacturerData);

  return (
    <FooterContainer as="footer">
      <FooterInnerContainer maxWidth="xl">
        <LogoTypography
          sx={{
            flexBasis: "45%",
            display: "flex",
            justifyContent: "center",
            color: theme.palette.primary.contrast,
          }}
        >
          {t("platform_name")}
        </LogoTypography>
        <nav>
          {navigationItems.map((item) => (
            <ul key={`list-column-${item[0].title || item[0].slug}`}>
              {item.map((link) => {
                const staticLink = link.static;
                const agbLink = link.agb;

                if (staticLink) {
                  return (
                    <li key={`list-${link.title}`}>
                      <Link
                        href={t(`links.${link.title}.link`)}
                        underline="hover"
                        size="small"
                        isActive={
                          router.asPath === t(`links.${link.title}.link`)
                        }
                      >
                        {t(`links.${link.title}.title`)}
                      </Link>
                    </li>
                  );
                }

                if (agbLink) {
                  return (
                    <li key={`list-${link.title}-${link.name}`}>
                      <Link
                        href={`${t("links.terms_and_conditions.link")}/${
                          link.slug
                        }`}
                        underline="hover"
                        size="small"
                        isActive={
                          router.asPath ===
                          `${t("links.terms_and_conditions.link")}/${link.slug}`
                        }
                      >
                        {t(`links.${link.title}.title`, { value: link.name })}
                      </Link>
                    </li>
                  );
                }

                return (
                  <li key={`list-${link.slug}`}>
                    <Link
                      href={`/${link.slug}`}
                      underline="hover"
                      size="small"
                      isActive={router.asPath === `/${link.slug}`}
                    >
                      {link.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          ))}
        </nav>
      </FooterInnerContainer>
    </FooterContainer>
  );
}

Footer.propTypes = {
  navigationData: T.arrayOf(T.shape({})),
  manufacturerData: T.arrayOf(T.shape({})),
};

Footer.defaultProps = {
  navigationData: [],
  manufacturerData: [],
};

export default Footer;
