const NAVIGATION_LINKS = [
  {
    title: "products",
    submenu: [
      {
        title: "product_overview",
      },
      {
        title: "all_products",
        withDivider: true,
      },
    ],
  },
  {
    title: "faq",
  },
  {
    title: "about_us",
  },
  {
    title: "blog",
  },
  {
    title: "contact",
    action: "anchor",
  },
];

export default NAVIGATION_LINKS;
